import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { updateUserData } from "../utils/api";
import CircularProgress from "@mui/material/CircularProgress";

const UserContainer = ({ open, handleClose, user , reload }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [name, setName] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [upi, setUpi] = useState("");

  const setData = () => {
    if (user) {
      setIsLoading(true);

      setName(user.name);
      setAadharNumber(user.aadharNumber);
      setIfsc(user.ifsc);
      setAccountNumber(user.accountNumber);
      setBankName(user.bankName);
      setUpi(user.upi);

      setIsLoading(false);
    }
  };

  const updateData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      name: name,
      ifsc: ifsc,
      bankName: bankName,
      accountNumber : accountNumber,
      upi : upi,
      aadharNumber : aadharNumber,
      userId : user._id,
    };
    const response = await updateUserData(data);
    if(response[0]){
      await reload();
        alert("Data updated")
    }else{
        alert("error")
    }
    handleClose();
  };

  useEffect(() => {
    setData();
  }, [user]);

  return (
    <div >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="noselect history__container"
      >
        {isLoading ? (
          <div className="dialog__container">
            <center>
              <CircularProgress />
            </center>
          </div>
        ) : (
          <div className="dialog__container">
            <form onSubmit={updateData} className="form">
              <div className="form_div">
                <label>Name: &nbsp;</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form_div">
                <label>Aadhar Number: &nbsp;</label>
                <input
                  type="text"
                  value={aadharNumber}
                  onChange={(e) => setAadharNumber(e.target.value)}
                  required
                />
              </div>
              <div className="form_div">
                <label>Ifsc: &nbsp;</label>
                <input
                  type="text"
                  value={ifsc}
                  onChange={(e) => setIfsc(e.target.value)}
                  required
                />
              </div>
              <div className="form_div">
                <label>Account Number: &nbsp;</label>
                <input
                  type="text"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  required
                />
              </div>
              <div className="form_div">
                <label>Bank Name: &nbsp;</label>
                <input
                  type="text"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  required
                />
              </div>
              <div className="form_div">
                <label>Upi Id: &nbsp;</label>
                <input
                  type="text"
                  value={upi}
                  onChange={(e) => setUpi(e.target.value)}
                  required
                />
              </div>
              
              <br />
              <button className="medium__button">Update</button>
            </form>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default UserContainer;
