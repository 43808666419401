import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import { adminAddToUserWallet } from "../utils/api";
import "../css/user.css";

const AddWallet = ({ open, handleClose, userId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState(0);

  const addToUserWallet = async () => {
    setIsLoading(true);
    const response = await adminAddToUserWallet(
        {
            "userId": userId,
            "amount": amount,
            "type": type,
            "message": message
        }
    );
    if(response[0]){
        handleClose();
    }
    setIsLoading(false);
  };
  const handleTypeChange = (e) => {
    setType(e.target.value);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        {isLoading ? (
          <div>
            <center>
              <CircularProgress />
            </center>
          </div>
        ) : (
          <div className="add-wallet">
            <form onSubmit={addToUserWallet} >
              <div className="form_div">
                <label>Amount:</label> &nbsp;
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                />
              </div>
              <div className="form_div">
                <label>Description:</label> &nbsp;
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </div>
              <div className="form_div">
              <label>Wallet Type:</label> &nbsp;
              <select onChange={handleTypeChange}>
                <option value="referralCoin">Referral Coin</option>
                <option value="promotionalCoin">Promotional Coin</option>
                <option value="appSharingCoin">App Sharing Coin</option>
                <option value="contentSharingCoin">Content Sharing Coin</option>
                <option value="rewardVideoCoin">Reward Video Coin</option>
              </select>
              </div>
              <center>
              <button type="submit">
                Submit
              </button>
              </center>
            </form>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default AddWallet;
