import { useState, useEffect } from "react";
import Loading from "../components/Loading";
import { addVideo, getAllSubscriptions } from "../utils/api";
import AlertDialog from "../components/AlertDialog.jsx";
import Checkbox from "@mui/material/Checkbox";
import AddIntractionType from "../components/AddIntractionType";
import { useNavigate } from "react-router-dom";
import useStore from "../utils/store";

const AddVideo = () => {
  let navigate = useNavigate();
  const isLoggedIn = useStore((state) => state.isLoggedIn);

  const [isLoading, setIsLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [url, setUrl] = useState("");
  const [ads, setAds] = useState([]);
  const [amount, setAmount] = useState(0);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [isYoutube, setIsYoutube] = useState(true);
  const [title, setTitle] = useState("");
  const [banner, setBanner] = useState("");
  const [authorName, setAuthorName] = useState("");

  const [open, setOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Error");
  const handleClose = () => {
    setOpen(false);
    setAlertTitle("Error");
  };

  const getData = async () => {
    const response = await getAllSubscriptions();
    if (response[0]) {
      setSubscriptions(response[1]);
    }
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (ads.length < 1) {
      alert("Add atleast 1 intraction");
    } else {
      setIsLoading(true);
      const data = {
        url: url,
        amount: amount,
        ads: ads,
        ids: subscriptionList,
        isYoutube: isYoutube,
        title: title,
        banner: banner,
        authorImage: "        ",
        authorName: authorName,
      };

      const response = await addVideo(data);
      if (response[0]) {
        setUrl("");
        setAmount(0);
        setAds([]);
        setSubscriptionList([]);
        setTitle("");
        setAuthorName("");
        setBanner("");
        setAlertTitle("Video Added Success");
        setOpen(true);
      } else {
        setAlertTitle("Error");
        setOpen(true);
      }
      setIsLoading(false);
    }
  };

  const checkData = async () => {
    if (!isLoggedIn) {
      navigate("/login" , {replace :true} );
    } else {
      getData();
    }
  };


  useEffect(() => {
    checkData();
  }, []);

  if (isLoading) return <Loading />;

  return (
    <div className="container noselect">
      <h2>Add New Video</h2>
      <AlertDialog
        open={open}
        handleClose={handleClose}
        selected={handleClose}
        title={alertTitle}
      />
      <form onSubmit={handleSubmit} className="form">
        <div className="form_div">
          <label>Video Url:</label> &nbsp;
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            required
          />
        </div>
        <div className="form_div_column">
          <label>Video Ads:</label> &nbsp;
          <div>
            {ads.map((ad, index) => (
              <div key={index}>
                <div>
                  {index + 1}. Intraction Type: {ad.interactionType}
                </div>
                {ad.text ? <div>Intraction text: {ad.text}</div> : <></>}
                {ad.url ? <div>Intraction url: {ad.url}</div> : <></>}
                {ad.banner ? <div>Intraction banner: {ad.banner}</div> : <></>}
                <hr />
              </div>
            ))}
          </div>
          <AddIntractionType intraction={ads} setNewIntraction={setAds} />
        </div>
        <div className="form_div">
          <label>Video Amount:</label> &nbsp;
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <div className="form_div">
          <label>Is Youtube Video:</label>
          <Checkbox
            checked={isYoutube}
            name="isActive"
            onChange={(e) => {
              const newvalue = e.target.checked;
              setIsYoutube(newvalue);
            }}
          />
        </div>
        {isYoutube ? (
          <></>
        ) : (
          <div className="form_div">
            <label>Video Title:</label> &nbsp;
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
        )}
        {isYoutube ? (
          <></>
        ) : (
          <div className="form_div">
            <label>Video Banner:</label> &nbsp;
            <input
              type="text"
              value={banner}
              onChange={(e) => setBanner(e.target.value)}
              required
            />
          </div>
        )}
        {isYoutube ? (
          <></>
        ) : (
          <div className="form_div">
            <label>Video Author Name:</label> &nbsp;
            <input
              type="text"
              value={authorName}
              onChange={(e) => setAuthorName(e.target.value)}
              required
            />
          </div>
        )}
        <br />
        <h3>Select Subscriptions: </h3>
        <br />
        {subscriptions.map((subscription, index) => (
          <div className="form_div" key={index}>
            <label>{subscription.name}</label> &nbsp;
            <Checkbox
              checked={subscriptionList.some(
                (element) => element === subscription._id
              )}
              name="isActive"
              onChange={(e) => {
                if (
                  subscriptionList.some(
                    (element) => element === subscription._id
                  )
                ) {
                  for (
                    let index = 0;
                    index < subscriptionList.length;
                    index++
                  ) {
                    const element = subscriptionList[index];
                    if (element === subscription._id) {
                      subscriptionList.splice(index, 1);
                      setSubscriptionList([...subscriptionList]);
                      break;
                    }
                  }
                } else {
                  const newList = [...subscriptionList, subscription._id];
                  setSubscriptionList(newList);
                }
              }}
            />
          </div>
        ))}
        <br />
        <button type="submit">Add Video</button>
      </form>
    </div>
  );
};

export default AddVideo;
