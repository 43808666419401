import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { walletHistory } from "../utils/api";
import CircularProgress from "@mui/material/CircularProgress";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Loading from '../components/Loading';
import { getAllAdmins } from '../utils/api';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const WalletDetails = ({ open, handleClose, userId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [histories, setHistories] = useState([]);

  const getWalletDetails = async () => {
    setIsLoading(true);
    const response = await walletHistory(userId);

    if (response[0]) {
      setHistories(response[1].history);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getWalletDetails();
  }, [userId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="noselect history__container"
        maxWidth="xl"
      >
        {isLoading ? (
          <div className="dialog__container">
            <center>
              <CircularProgress />
            </center>
          </div>
        ) : (
          <div>
            
              <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">S.NO.</StyledTableCell>
                    <StyledTableCell align="center">Date</StyledTableCell>
                    <StyledTableCell align="center">Amount</StyledTableCell>
                    <StyledTableCell align="center">Description</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {histories.map((history , index) => (
                    <StyledTableRow key={history._id}>
                      <StyledTableCell align="center" >{index+1}.</StyledTableCell>
                      <StyledTableCell align="center" >{history.date}</StyledTableCell>
                      <StyledTableCell align="center">{history.balance}</StyledTableCell>
                      <StyledTableCell align="center">{history.title}</StyledTableCell>
                      
                     
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default WalletDetails;
