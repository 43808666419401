import { useState } from "react";

const AddIntractionType = ({ intraction, setNewIntraction }) => {
  const [type, setType] = useState("Text");
  const [text, setText] = useState("");
  const [url, setUrl] = useState("");
  const [banner, setBanner] = useState("");

  const handleTypeChange = (e) => {
    setType(e.target.value);
    setText("");
    setUrl("");
    setBanner("");
  };

  const handleAdd = (e) => {
    e.preventDefault();
    let v = {
      interactionType: type,
      text: text,
      banner: banner,
      url: url,
    };
    setText("");
    setUrl("");
    setBanner("");
    setNewIntraction([...intraction, v]);
  };

  return (
    <div>
      <select onChange={handleTypeChange}>
        <option>Text</option>
        <option>Link</option>
        <option>Banner</option>
        <option>ClickAbleBanner</option>
        <option>Video</option>
      </select>
      {type === "Text" ? (
        <input
          type="text"
          value={text}
          placeholder="Text ...."
          onChange={(e) => setText(e.target.value)}
        />
      ) : (
        <></>
      )}
      {type === "Link" || type === "ClickAbleBanner" || type === "Video" ? (
        <input
          type="text"
          value={url}
          placeholder="Url ...."
          onChange={(e) => setUrl(e.target.value)}
        />
      ) : (
        <></>
      )}
      {type === "Banner" || type === "ClickAbleBanner" ? (
        <input
          type="text"
          value={banner}
          placeholder="Banner ...."
          onChange={(e) => setBanner(e.target.value)}
        />
      ) : (
        <></>
      )}

      <button onClick={handleAdd} className="medium-button">
        Add
      </button>
    </div>
  );
};

export default AddIntractionType;
