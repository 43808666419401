import { useEffect, useState } from "react";
import Loading from "./Loading";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import useStore from "../utils/store";
import { adminUpdateAccess } from "../utils/api";
const UpdateAccessContainer = ({ open, handleClose, admin, reload }) => {
    const adminId = useStore((state) => state.adminId);

  const [isLoading, setIsLoading] = useState(true);

  const [isUser, setIsUser] = useState(false);
  const [isRedeem, setIsRedeem] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isAccess, setIsAccess] = useState(false);
  const [isUserWallet, setIsUserWallet] = useState(false);
  const [isUserWalletHistory, setIsUserWalletHistory] = useState(false);
  const [isUserWalletNotificationHistory, setIsUserWalletNotificationHistory] =
    useState(false);
  const [isDeleteUser, setIsDeleteUser] = useState(false);
  const [isUserSubscriptionHistory, setIsUserSubscriptionHistory] =
    useState(false);
  const [isAddUser, setIsAddUser] = useState(false);
  const [isUserSubscription, setIsUserSubscription] = useState(false);
  const [isAddSubscription, setIsAddSubcription] = useState(false);
  const [isAddVideo, setIsAddVideo] = useState(false);
  const [isEditVideo, setIsEditVideo] = useState(false);
  const [isDeleteVideo, setIsDeleteVideo] = useState(false);
  const [isEditSubscription, setIsEditSubscription] = useState(false);
  const [isDeleteSubscription, setIsDeleteSubcription] = useState(false);
  const [isEditUser, setIsEditUser] = useState(false);

  const setData = () => {
    if (admin) {
      setIsLoading(true);
        setIsUser(admin.isUser);
        setIsAccess(admin.isAccess);
        setIsAddSubcription(admin.isAddSubscription);
        setIsAddUser(admin.isAddUser);
        setIsAddVideo(admin.isAddVideo);
        setIsDeleteSubcription(admin.isDeleteSubscription);
        setIsDeleteUser(admin.isDeleteUser);
        setIsDeleteVideo(admin.isDeleteVideo);
        setIsEditSubscription(admin.isEditSubscription);
        setIsEditUser(admin.isEditUser);
        setIsEditVideo(admin.isEditVideo);
        setIsRedeem(admin.isRedeem);
        setIsSubscription(admin.isSubscription);
        setIsUserSubscription(admin.isUserSubscription)
        setIsUserSubscriptionHistory(admin.isUserSubscriptionHistory);
        setIsUserWallet(admin.isUserWallet);
        setIsUserWalletHistory(admin.isUserWalletHistory);
        setIsUserWalletNotificationHistory(admin.isUserWalletNotificationHistory);
        setIsVideo(admin.isVideo)
      setIsLoading(false);
    }
  };
  const updateData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {    
      isUser: isUser,
      isRedeem: isRedeem,
      isSubscription: isSubscription,
      isVideo: isVideo,
      isAccess: isAccess,
      isUserWallet: isUserWallet,
      isUserWalletHistory: isUserWalletHistory,
      isUserWalletNotificationHistory: isUserWalletNotificationHistory,
      isDeleteUser: isDeleteUser,
      isUserSubscriptionHistory: isUserSubscriptionHistory,
      isAddUser: isAddUser,
      isUserSubscription: isUserSubscription,
      isAddSubscription: isAddSubscription,
      isAddVideo: isAddVideo,
      isEditVideo: isEditVideo,
      isDeleteVideo: isDeleteVideo,
      isEditSubscription: isEditSubscription,
      isDeleteSubscription: isDeleteSubscription,
      isEditUser: isEditUser
    };
    const response = await adminUpdateAccess(admin._id , data);
    if (response[0]) {
      setIsUser(false);
      setIsRedeem(false);
      setIsAccess(false);
      setIsSubscription(false);
      setIsVideo(false);
      setIsUserWallet(false);
      setIsUserWalletHistory(false);
      setIsUserWalletNotificationHistory(false);
      setIsDeleteUser(false);
      setIsUserSubscriptionHistory(false);
      setIsAddUser(false);
      setIsUserSubscription(false);
      setIsAddSubcription(false);
      setIsAddVideo(false);
      setIsEditVideo(false);
      setIsDeleteVideo(false);
      setIsEditSubscription(false);
      setIsDeleteSubcription(false);
      setIsEditUser(false);
      alert("Admin Access Updated");
    }else{
        alert("Error")
    }
    setIsLoading(false);
    reload()
    handleClose();
  };
  useEffect(() => {
    setData();
  }, [admin]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="noselect history__container"
      >
        {isLoading ? (
          <div className="dialog__container">
            <center>
              <CircularProgress />
            </center>
          </div>
        ) : (
          <div className="container noselect ">
            <h2>Update Admin Access</h2>
            <form onSubmit={updateData} className="form max-width">
              <div className="form_div">
                <label>View User Access: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsUser(e.target.checked)}
                  checked={isUser}
                />
              </div>
              <div className="form_div">
                <label>Edit User Access: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsEditUser(e.target.checked)}
                  checked={isEditUser}
                />
              </div>
              <div className="form_div">
                <label>User Wallet Access: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsUserWallet(e.target.checked)}
                  checked={isUserWallet}
                />
              </div>
              <div className="form_div">
                <label>User Wallet History Access: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsUserWalletHistory(e.target.checked)}
                  checked={isUserWalletHistory}
                />
              </div>
              <div className="form_div">
                <label>User Notification History Access: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    setIsUserWalletNotificationHistory(e.target.checked)
                  }
                  checked={isUserWalletNotificationHistory}
                />
              </div>
              <div className="form_div">
                <label>Delete User Access: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsDeleteUser(e.target.checked)}
                  checked={isDeleteUser}
                />
              </div>
              <div className="form_div">
                <label>User Subscription History Access: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    setIsUserSubscriptionHistory(e.target.checked)
                  }
                  checked={isUserSubscriptionHistory}
                />
              </div>
              <div className="form_div">
                <label>Add User Access: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsAddUser(e.target.checked)}
                  checked={isAddUser}
                />
              </div>
              <div className="form_div">
                <label>Add User Subscription Access: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsUserSubscription(e.target.checked)}
                  checked={isUserSubscription}
                />
              </div>

              <div className="form_div">
                <label>Redeem Acces: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsRedeem(e.target.checked)}
                  checked={isRedeem}
                />
              </div>
              <div className="form_div">
                <label>Subscription View Acces: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsSubscription(e.target.checked)}
                  checked={isSubscription}
                />
              </div>
              <div className="form_div">
                <label>Subscription Add Acces: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsAddSubcription(e.target.checked)}
                  checked={isAddSubscription}
                />
              </div>
              <div className="form_div">
                <label>Subscription Edit Acces: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsEditSubscription(e.target.checked)}
                  checked={isEditSubscription}
                />
              </div>
              <div className="form_div">
                <label>Subscription Delete Acces: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsDeleteSubcription(e.target.checked)}
                  checked={isDeleteSubscription}
                />
              </div>
              <div className="form_div">
                <label>Video View Acces: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsVideo(e.target.checked)}
                  checked={isVideo}
                />
              </div>
              <div className="form_div">
                <label>Add Video Acces: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsAddVideo(e.target.checked)}
                  checked={isAddVideo}
                />
              </div>
              <div className="form_div">
                <label>Edit Video Acces: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsEditVideo(e.target.checked)}
                  checked={isEditVideo}
                />
              </div>
              <div className="form_div">
                <label>Delete Video Acces: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsDeleteVideo(e.target.checked)}
                  checked={isDeleteVideo}
                />
              </div>
              <div className="form_div">
                <label>New Admin Create Acces: &nbsp; </label>
                <input
                  type="checkbox"
                  onChange={(e) => setIsAccess(e.target.checked)}
                  checked={isAccess}
                />
              </div>
              <button type="submit">Update</button>
            </form>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default UpdateAccessContainer;
