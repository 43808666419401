import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import userEvent from "@testing-library/user-event";
import { giveUserSubscription } from "../utils/api";

const SubscriptionContainer = ({
  open,
  handleClose,
  userId,
  subscriptions,
  reload,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const addSubscription = async (id) => {
    setIsLoading(true);
    const data = { userId: userId, subscriptionId: id };
    const response = await giveUserSubscription(data);
    if (response) {
      await reload();
      alert("Subscription given to users");
      handleClose();
      setIsLoading(false);
    } else {
      alert("error");
      setIsLoading(false);
    }
  };


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="noselect history__container"
      >
        {isLoading ? (
          <div className="dialog__container">
            <center>
              <CircularProgress />
            </center>
          </div>
        ) : (
          <div style={{ padding: "1em" }}>
            {subscriptions.map((subscription, index) => (
              <div key={index} className="row_spacer">
                <div>
                  {" "}
                  <b>{index + 1}.</b> {subscription.name}
                </div>
                <button
                  className="small-button"
                  onClick={(e) => {
                    e.preventDefault();
                    addSubscription(subscription._id);
                  }}
                >
                  Add Subscription{" "}
                </button>
              </div>
            ))}
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default SubscriptionContainer;
