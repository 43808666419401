import { useState } from "react";
import Loading from "../components/Loading";
import { adminCreate } from "../utils/api";

const AddAdmin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isUser, setIsUser] = useState(false);
  const [isRedeem, setIsRedeem] = useState(false);
  const [isSubscription, setIsSubcription] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isAccess, setIsAccess] = useState(false);
  const [isUserWallet, setIsUserWallet] = useState(false);
  const [isUserWalletHistory, setIsUserWalletHistory] = useState(false);
  const [isUserWalletNotificationHistory, setIsUserWalletNotificationHistory] = useState(false);
  const [isDeleteUser, setIsDeleteUser] = useState(false);
  const [isUserSubscriptionHistory, setIsUserSubscriptionHistory] = useState(false);
  const [isAddUser, setIsAddUser] = useState(false);
  const [isUserSubscription, setIsUserSubscription] = useState(false);
  const [isAddSubscription, setIsAddSubcription] = useState(false);
  const [isAddVideo, setIsAddVideo] = useState(false);
  const [isEditVideo, setIsEditVideo] = useState(false);
  const [isDeleteVideo, setIsDeleteVideo] = useState(false);
  const [isEditSubscription, setIsEditSubcription] = useState(false);
  const [isDeleteSubscription, setIsDeleteSubcription] = useState(false);
  const [isEditUser, setIsEditUser] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      email: email,
      password: password,
      isUser: isUser,
      isRedeem: isRedeem,
      isSubscription: isSubscription,
      isVideo: isVideo,
      isAccess: isAccess,
      isUserWallet: isUserWallet,
      isUserWalletHistory: isUserWalletHistory,
      isUserWalletNotificationHistory: isUserWalletNotificationHistory,
      isDeleteUser: isDeleteUser,
      isUserSubscriptionHistory: isUserSubscriptionHistory,
      isAddUser: isAddUser,
      isUserSubscription: isUserSubscription,
      isAddSubscription: isAddSubscription,
      isAddVideo: isAddVideo,
      isEditVideo: isEditVideo,
      isDeleteVideo: isDeleteVideo,
      isEditSubscription: isEditSubscription,
      isDeleteSubscription: isDeleteSubscription,
      isEditUser: isEditUser
    };
    const response = await adminCreate(data);
    if (response[0]) {
      setEmail("");
      setPassword("");
      setIsUser(false);
      setIsRedeem(false);
      setIsAccess(false);
      setIsSubcription(false);
      setIsVideo(false);
      setIsUserWallet(false);
      setIsUserWalletHistory(false);
      setIsUserWalletNotificationHistory(false);
      setIsDeleteUser(false);
      setIsUserSubscriptionHistory(false);
      setIsAddUser(false);
      setIsUserSubscription(false);
      setIsAddSubcription(false);
      setIsAddVideo(false);
      setIsEditVideo(false);
      setIsDeleteVideo(false);
      setIsEditSubcription(false);
      setIsDeleteSubcription(false);
      setIsEditUser(false);
      alert("New Admin Added");
    }
    setIsLoading(false);
  };

  if (isLoading) return <Loading />;

  return (
    <div className="container noselect">
      <h2>Add Admin</h2>
      <form onSubmit={handleSubmit} className="form">
        <div className="form_div">
          <label>Email: &nbsp; </label>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form_div">
          <label>Password: &nbsp; </label>
          <input
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form_div">
          <label>View User Access: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsUser(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>Edit User Access: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsEditUser(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>User Wallet Access: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsUserWallet(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>User Wallet History Access: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsUserWalletHistory(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>User Notification History Access: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsUserWalletNotificationHistory(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>Delete User Access: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsDeleteUser(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>User Subscription History Access: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsUserSubscriptionHistory(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>Add User Access: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsAddUser(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>Add User Subscription Access: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsUserSubscription(e.target.checked)}
          />
        </div>

        <div className="form_div">
          <label>Redeem Acces: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsRedeem(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>Subscription View Acces: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsSubcription(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>Subscription Add Acces: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsAddSubcription(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>Subscription Edit Acces: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsEditSubcription(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>Subscription Delete Acces: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsDeleteSubcription(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>Video View Acces: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsVideo(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>Add Video Acces: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsAddVideo(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>Edit Video Acces: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsEditVideo(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>Delete Video Acces: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsDeleteVideo(e.target.checked)}
          />
        </div>
        <div className="form_div">
          <label>New Admin Create Acces: &nbsp; </label>
          <input
            type="checkbox"
            onChange={(e) => setIsAccess(e.target.checked)}
          />
        </div>
        <button type="submit">Add</button>
      </form>
    </div>
  );
};

export default AddAdmin;
