import { useState, useEffect } from "react";
import Loading from "../components/Loading";
import {
  adminGetSystemInformation,
  getAllSystemValues,
  updateSystemValue,
} from "../utils/api";
import { useNavigate } from "react-router-dom";
import GroupIcon from "@mui/icons-material/Group";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import SwitchVideoIcon from "@mui/icons-material/SwitchVideo";
import useStore from "../utils/store";

const HomePage = () => {
  let navigate = useNavigate();
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const isAccess = useStore((state) => state.isAccess);
  const [redeemAmount, setRedeemAmount] = useState(0);
  const [referralFirst, setReferralFirst] = useState(0);
  const [referralSecond, setReferralSecond] = useState(0);
  const [referralThird, setReferralThird] = useState(0);
  const [referralFour, setReferralFour] = useState(0);
  const [referralText, setReferralText] = useState("");
  const [referralFive, setReferralFive] = useState(0);
  const [referralFixedPrice, setReferralFixedPrice] = useState(0);
  const [Banner1, setBanner1] = useState("");
  const [Banner2, setBanner2] = useState("");
  const [videoSharingReward, setVideoSharingReward] = useState(0);
  const [appSharingReward, setAppSharingReward] = useState(0);
  const [banner1Type, setBanner1Type] = useState("");
  const [banner2Type, setBanner2Type] = useState("");
  const [adminCharges, setAdminCharges] = useState(0);
  const [subscriptionBanner, setSubscriptionBanner] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [totalUser, setTotalUsers] = useState(0);
  const [totalSubscription, setTotalSubscription] = useState(0);
  const [totalVideos, setTotalVideos] = useState(0);

  const getSystemValue = async () => {
    const responseSystemInfo = await adminGetSystemInformation();

    if (responseSystemInfo[0]) {
      setTotalUsers(responseSystemInfo[1].users);
      setTotalSubscription(responseSystemInfo[1].subscriptions);
      setTotalVideos(responseSystemInfo[1].videos);
    }
    const response = await getAllSystemValues();
    if (response[0]) {
      setRedeemAmount(response[1].redeemAmount);
      setReferralFirst(response[1].referralFirst);
      setReferralSecond(response[1].referralSecond);
      setReferralText(response[1].referralText);
      setReferralThird(response[1].referralThird);
      setReferralFour(response[1].referralFour);
      setReferralFive(response[1].referralFive);
      setReferralFixedPrice(response[1].referralFixedPrice);
      setBanner1(response[1].Banner1);
      setBanner2(response[1].Banner2);
      setVideoSharingReward(response[1].videoSharingReward);
      setAppSharingReward(response[1].appSharingReward);
      setBanner1Type(response[1].Banner1Type);
      setBanner2Type(response[1].Banner2Type);
      setAdminCharges(response[1].adminCharges);
      setSubscriptionBanner(response[1].subscriptionBanner);
    } else {
      alert(`${response[1].message}`);
    }
    setIsLoading(false);
  };

  const checkData = async () => {
    if (isLoggedIn) {
      getSystemValue();
    } else {
      navigate("/login", { replace: true });
    }
  };

  useEffect(() => {
    checkData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      redeemAmount: redeemAmount,
      referralFirst: referralFirst,
      referralSecond: referralSecond,
      referralThird: referralThird,
      referralFour: referralFour,
      referralText: referralText,
      referralFive: referralFive,
      referralFixedPrice: referralFixedPrice,
      videoSharingReward: videoSharingReward,
      appSharingReward: appSharingReward,
      Banner1: Banner1,
      Banner2: Banner2,
      Banner1Type: banner1Type,
      Banner2Type: banner2Type,
      adminCharges: adminCharges,
      subscriptionBanner: subscriptionBanner,
    };
    const response = await updateSystemValue(data);
    if (response[0]) {
      setRedeemAmount(response[1].redeemAmount);
      setReferralFirst(response[1].referralFirst);
      setReferralSecond(response[1].referralSecond);
      setReferralThird(response[1].referralThird);
      setSubscriptionBanner(response[1].subscriptionBanner);
      setReferralFour(response[1].referralFour);
      setReferralFive(response[1].referralFive);
      setReferralFixedPrice(response[1].referralFixedPrice);
      setVideoSharingReward(response[1].videoSharingReward);
      setReferralText(response[1].referralText);
      setAppSharingReward(response[1].appSharingReward);
      setBanner1(response[1].Banner1);
      setBanner2(response[1].Banner2);
      setBanner1Type(response[1].Banner1Type);
      setBanner2Type(response[1].Banner2Type);
      setAdminCharges(response[1].adminCharges);
    } else {
      alert(`${response[1].message}`);
    }
    setIsLoading(false);
  };

  if (isLoading) return <Loading />;

  return (
    <div className="container noselect">
      <div className="wrapper">
        <div className="icon_container" style={{ backgroundColor: "red" }}>
          <GroupIcon color="white" />
          <div>Total Users: {totalUser}</div>
        </div>
        <div className="icon_container" style={{ backgroundColor: "blue" }}>
          <VideoSettingsIcon color="white" />
          <div>Total Subscriptions: {totalSubscription}</div>
        </div>
        <div className="icon_container" style={{ backgroundColor: "green" }}>
          <SwitchVideoIcon color="white" />
          <div>Total Videos: {totalVideos}</div>
        </div>
      </div>

      <br />
      {isAccess ? (
        <>
          <h2>Values</h2>
          <form onSubmit={handleSubmit} className="form">
            <div className="form_div">
              <label>Minimum Redeem Amount: &nbsp; {" (₹) "} </label>
              <input
                type="text"
                value={redeemAmount}
                onChange={(e) => setRedeemAmount(e.target.value)}
                required
              />
            </div>
            <div className="form_div">
              <label>Referral Text</label>
              <input
                type="text"
                value={referralText}
                onChange={(e) => setReferralText(e.target.value)}
                required
              />
            </div>

            <div className="form_div">
              <label>Subscription Text</label>
              <input
                type="text"
                value={subscriptionBanner}
                onChange={(e) => setSubscriptionBanner(e.target.value)}
                required
              />
            </div>

            <div className="form_div">
              <label>Referral 1: &nbsp; {" (₹) "} </label>
              <input
                type="text"
                value={referralFirst}
                onChange={(e) => setReferralFirst(e.target.value)}
                required
              />
            </div>
            <div className="form_div">
              <label>Referral 2: &nbsp; {" (₹) "} </label>
              <input
                type="text"
                value={referralSecond}
                onChange={(e) => setReferralSecond(e.target.value)}
                required
              />
            </div>
            <div className="form_div">
              <label>Referral 3: &nbsp; {" (₹) "} </label>
              <input
                type="text"
                value={referralThird}
                onChange={(e) => setReferralThird(e.target.value)}
                required
              />
            </div>
            <div className="form_div">
              <label>Referral 4: &nbsp; {" (₹) "} </label>
              <input
                type="text"
                value={referralFour}
                onChange={(e) => setReferralFour(e.target.value)}
                required
              />
            </div>
            <div className="form_div">
              <label>Referral 5: &nbsp; {" (₹) "} </label>
              <input
                type="text"
                value={referralFive}
                onChange={(e) => setReferralFive(e.target.value)}
                required
              />
            </div>
            <div className="form_div">
              <label>Referral Fixed ( after 5 ): &nbsp; {" (₹) "} </label>
              <input
                type="text"
                value={referralFixedPrice}
                onChange={(e) => setReferralFixedPrice(e.target.value)}
                required
              />
            </div>
            <div className="form_div">
              <label>Admin Charges: &nbsp; {" (₹) "} </label>
              <input
                type="number"
                value={adminCharges}
                onChange={(e) => setAdminCharges(e.target.value)}
                required
              />
            </div>
            <div className="form_div">
              <label>Banner 1: &nbsp;</label>
              <div className="row">
                <input
                  type="text"
                  value={Banner1}
                  onChange={(e) => setBanner1(e.target.value)}
                  required
                />
                <div>
                  <button
                    className={
                      banner1Type === "Null"
                        ? "selected_button"
                        : "unselected_button"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setBanner1Type("Null");
                    }}
                  >
                    Remove
                  </button>
                  <button
                    className={
                      banner1Type === "Banner"
                        ? "selected_button"
                        : "unselected_button"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setBanner1Type("Banner");
                    }}
                  >
                    Banner
                  </button>
                  <button
                    className={
                      banner1Type === "Video"
                        ? "selected_button"
                        : "unselected_button"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setBanner1Type("Video");
                    }}
                  >
                    Youtube Video
                  </button>
                </div>
              </div>
            </div>

            <div className="form_div">
              <label>Banner 2:</label>
              <div className="row">
                <input
                  type="text"
                  value={Banner2}
                  onChange={(e) => setBanner2(e.target.value)}
                  required
                />

                <div>
                  <button
                    className={
                      banner2Type === "Null"
                        ? "selected_button"
                        : "unselected_button"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setBanner2Type("Null");
                    }}
                  >
                    Remove
                  </button>
                  <button
                    className={
                      banner2Type === "Banner"
                        ? "selected_button"
                        : "unselected_button"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setBanner2Type("Banner");
                    }}
                  >
                    Banner
                  </button>
                  <button
                    className={
                      banner2Type === "Video"
                        ? "selected_button"
                        : "unselected_button"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setBanner2Type("Video");
                    }}
                  >
                    Youtube Video
                  </button>
                </div>
              </div>
            </div>
            <div className="form_div">
              <label>Video Sharing Reward: &nbsp; {" (₹) "} </label>
              <input
                type="number"
                value={videoSharingReward}
                onChange={(e) => setVideoSharingReward(e.target.value)}
                required
              />
            </div>
            <div className="form_div">
              <label>App Sharing Reward: &nbsp; {" (₹) "} </label>
              <input
                type="number"
                value={appSharingReward}
                onChange={(e) => setAppSharingReward(e.target.value)}
                required
              />
            </div>
            <br />
            <button type="submit"> Update </button>
          </form>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default HomePage;
