import { useState, useEffect } from "react";
import Loading from "../components/Loading";
import { getSubscriptionHistory } from "../utils/api";
import { useNavigate } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import useStore from "../utils/store";

import Collapsible from "react-collapsible";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SubscriptionHistory = () => {
  let navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [histories, setHistories] = useState([]);
  const isLoggedIn = useStore((state) => state.isLoggedIn);

  const subscriptionHistory = async () => {
    const response = await getSubscriptionHistory();
    if (response[0]) {
      setHistories(response[1]);
    } else {
      alert(response[1].message);
    }

    setIsLoading(false);
  };

  
  const checkData = async () => {
    if (!isLoggedIn) {
      navigate("/login" , {replace :true} );
    } else {
      subscriptionHistory();
    }
  };


  useEffect(() => {
    checkData();
  }, []);
  if (isLoading) return <Loading />;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">User Name</StyledTableCell>
            <StyledTableCell align="center">User Email</StyledTableCell>
            <StyledTableCell align="center">
              Subscription History
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {histories.map((h, index) => (
            <StyledTableRow key={h._id}>
              <StyledTableCell align="center">{h.user.name}</StyledTableCell>
              <StyledTableCell align="center">{h.user.email}</StyledTableCell>
              <StyledTableCell align="center">
                <Collapsible
                  trigger="Click Here To View"
                  className="collapse noselect"
                >
                  {h.history.map((textHistory, i) => (
                    <div className="noselect" key={i}>
                      <span>
                        <b>{i + 1}.</b> {textHistory}
                      </span>
                    </div>
                  ))}
                </Collapsible>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SubscriptionHistory;
