import axios from "axios";

const URL = "https://api.swisskraft.co.in";
// const URL = "http://localhost:8000";

export const getAllUsers = async () => {
  try {
    var response = await axios.get(`${URL}/admin/users`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
};

export const getAllSubscriptions = async () => {
  try {
    var response = await axios.get(`${URL}/admin/subscriptions`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
};

export const addSubscription = async (data) => {
  try {
    var response = await axios.post(`${URL}/admin/subscription`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
};

export const getAllSystemValues = async () => {
  try {
    var response = await axios.get(`${URL}/admin/systemvalues`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
};

export const walletHistory = async (userId) => {
  try {
    var response = await axios.get(`${URL}/user/${userId}/wallet/history`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
};

export const getSubscriptionHistory = async (userId) => {
  try {
    var response = await axios.get(`${URL}/admin/subscription-history`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
};

export const getAllRedeemRequest = async () => {
  try {
    var response = await axios.get(`${URL}/admin/redeem-requests`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
};

export const updateSystemValue = async (data) => {
  try {
    var response = await axios.put(`${URL}/admin/systemvalues`, data);

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
};

export const updateUserData = async (data) => {
  try {
    var response = await axios.put(`${URL}/admin/user`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
};

export const addNewUser = async (data) => {
  try {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var response = await axios.post(`${URL}/admin/user`, data, config);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
};


export const updateRedeemRequest = async ( id , data) => {
  try {
    var response = await axios.put(`${URL}/admin/redeem-request/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
};

export const unsubscribedUser = async ( ) => {
  try {
    var response = await axios.get(`${URL}/admin/unsubscribed-user`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
};


export const giveUserSubscription = async(data)=>{
  try {
    var response = await axios.put(`${URL}/admin/user/add-subscription`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}


export const addVideo = async(data)=>{
  try {
    var response = await axios.put(`${URL}/admin/subscription/add-video`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}

export const getNotifications = async(id)=>{
  try {
    var response = await axios.get(`${URL}/admin/notifications/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}

export const removeVideoFromSubscription = async(data)=>{
  try {
    var response = await axios.put(`${URL}/admin/subscription/remove-video` , data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}


export const getAllVideos = async()=>{
  try {
    var response = await axios.get(`${URL}/admin/all-videos` );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
};

export const adminEditSubscription = async(data)=>{
  try {
    var response = await axios.put(`${URL}/admin/subscription`, data );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}

export const adminEditVideo = async(data)=>{
  try {
    var response = await axios.put(`${URL}/admin/edit-video`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}

export const adminDeleteSubscription =  async(id) =>{
  try {
    var response = await axios.delete(`${URL}/admin/subscription/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}

export const adminDeleteVideo  = async(id)=>{
  try {
    var response = await axios.delete(`${URL}/admin/delete-video/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}


export const adminDeleteUser = async(id)=>{
  try {
    var response = await axios.delete(`${URL}/admin/delete-user/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}

export const adminGetSystemInformation = async()=>{
  try {
    var response = await axios.get(`${URL}/admin/systemInformation`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}

export const adminLogin = async(data)=>{
  try {
    var response = await axios.post(`${URL}/admin/login`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}

export const adminUpdatePassword = async(data)=>{
  try {
    var response  = await axios.put(`${URL}/admin/update-password`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}

export const adminUpdateAccess = async(id , data)=>{
  try {
    var response  = await axios.put(`${URL}/admin/admin-update/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}

export const adminVerifyPassword = async(data)=>{
  try {
    var response  = await axios.post(`${URL}/admin/check-password`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}

export const adminCreate = async(data)=>{
  try {
    var response = await axios.post(`${URL}/admin/signup`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}


export const getAllAdmins = async() =>{
  try {
    var response = await axios.get(`${URL}/admin/get-all-admin`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}

export const adminAddToUserWallet = async(data) =>{
  try {
    var response = await axios.post(`${URL}/admin/update/wallet`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.message];
  }
}