import { useState, useEffect } from "react";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Loading from "../components/Loading";
import { getAllAdmins } from "../utils/api";
import useStore from "../utils/store";
import PasswordContainer from "../components/PasswordContainer";
import UpdateAccessContainer from "../components/UpdateAccessContainer";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Admins = () => {
  const [admins, setAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isAccess = useStore((state) => state.isAccess);
  const getData = async () => {
    const response = await getAllAdmins();

    if (response[0]) {
      setAdmins(response[1]);
    }
    setIsLoading(false);
  };

  const [email, setEmail] = useState();
  const [emailContainerOpen, setEmailContainerOpen] = useState(false);

  const handleCloseUserContainer = () => {
    setEmailContainerOpen(false);
  };

  const [admin, setAdmin] = useState();
  const [adminContainerOpen, setAdminContainerOpen] = useState(false);

  const handleCloseAdminContainer = () => {
    setAdminContainerOpen(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) return <Loading />;

  return (
    <div style={{ marginLeft: "20px" }}>
      <PasswordContainer
        open={emailContainerOpen}
        handleClose={handleCloseUserContainer}
        email={email}
        reload={getData}
      />
      <UpdateAccessContainer
        open={adminContainerOpen}
        handleClose={handleCloseAdminContainer}
        admin={admin}
        reload={getData}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">S.NO.</StyledTableCell>
              <StyledTableCell align="center">Email</StyledTableCell>
              <StyledTableCell align="center">Password</StyledTableCell>
              {isAccess && (
                <StyledTableCell align="center">Update Access</StyledTableCell>
              )}
              {isAccess && (
                <StyledTableCell align="center">
                  Change password
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {admins.map((admin, index) => (
              <StyledTableRow key={admin._id}>
                <StyledTableCell align="center">{index + 1}.</StyledTableCell>
                <StyledTableCell align="center">{admin.email}</StyledTableCell>
                <StyledTableCell align="center">
                  {admin.password}
                </StyledTableCell>
                {isAccess && (
                  <StyledTableCell align="center">
                    <button
                      className="small-button"
                      onClick={(e) => {
                        e.preventDefault();
                        setAdmin(admin);
                        setAdminContainerOpen(true);
                      }}
                    >
                      Update Access
                    </button>
                  </StyledTableCell>
                )}
                {isAccess && (
                  <StyledTableCell align="center">
                    <button
                      className="small-button"
                      onClick={(e) => {
                        e.preventDefault();
                        setEmail(admin.email);
                        setEmailContainerOpen(true);
                      }}
                    >
                      Update Password
                    </button>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Admins;
