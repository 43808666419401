import { useEffect, useState } from "react";
import { getAllVideos } from "../utils/api";
import Loading from "../components/Loading";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Collapsible from "react-collapsible";
import { useNavigate } from "react-router-dom";
import useStore from "../utils/store";
import SearchBar from "material-ui-search-bar";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Videos = () => {
  let navigate = useNavigate();
  const isLoggedIn = useStore((state) => state.isLoggedIn);

  const [isLoading, setIsLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [searched, setSearched] = useState("");

  const getData = async () => {
    setIsLoading(true);
    const response = await getAllVideos();
    if (response[0]) {
      setVideos(response[1]);
    }
    setIsLoading(false);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = videos.filter((row) => {
      return row.title.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setVideos(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
    window.location.reload();
  };

  const checkData = async () => {
    if (!isLoggedIn) {
      navigate("/login", { replace: true });
    } else {
      getData();
    }
  };

  useEffect(() => {
    checkData();
  }, []);

  if (isLoading) return <Loading />;
  return (
    <div style={{ marginLeft: "20px", marginRight: "0px" }}>
      <Paper>
        <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Title</StyledTableCell>
                <StyledTableCell align="center">Url</StyledTableCell>
                <StyledTableCell align="center">Ads</StyledTableCell>
                <StyledTableCell align="center">Views</StyledTableCell>
                <StyledTableCell align="center">Amount</StyledTableCell>
                <StyledTableCell align="center">Author Name</StyledTableCell>
                <StyledTableCell align="center">Banner</StyledTableCell>
                <StyledTableCell align="center">isYoutube</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {videos.map((video) => (
                <StyledTableRow key={video._id}>
                  <StyledTableCell align="center">
                    {video.title}
                  </StyledTableCell>
                  <StyledTableCell align="center">{video.url}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Collapsible
                      trigger="Click Here To View"
                      className="collapse noselect"
                    >
                      {video.ads.map((ad, i) => (
                        <div key={i} className="expanded_column">
                          <div>
                            {i + 1}. Intraction Type: {ad.interactionType}
                          </div>
                          {ad.text ? (
                            <div>Intraction text: {ad.text}</div>
                          ) : (
                            <></>
                          )}
                          {ad.url ? <div>Intraction url: {ad.url}</div> : <></>}
                          {ad.banner ? (
                            <div>Intraction banner: {ad.banner}</div>
                          ) : (
                            <></>
                          )}
                          <hr />
                        </div>
                      ))}
                    </Collapsible>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {video.views}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    ₹{video.amount}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {video.authorName}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <img
                      src={video.banner}
                      alt="banner"
                      height="100px"
                      width="100px"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {video.isYoutube ? "True" : "False"}
                  </StyledTableCell>

                  <StyledTableCell align="center"></StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default Videos;
