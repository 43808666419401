import React, { useEffect, useState } from "react";
import "../css/login.css";
import { useNavigate } from "react-router-dom";
import useStore from "../utils/store";
import { adminLogin } from "../utils/api";

const Login = () => {
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const login = useStore((state) => state.login);
  const setIsUser = useStore((state) => state.setIsUser);
  const setIsUserWallet = useStore((state) => state.setIsUserWallet);
  const setIsRedeem = useStore((state) => state.setIsRedeem);
  const setIsSubcription = useStore((state) => state.setIsSubcription);
  const setIsVideo = useStore((state) => state.setIsVideo);
  const setIsAccess = useStore((state) => state.setIsAccess);
  const setIsUserWalletHistory = useStore((state) => state.setIsUserWalletHistory);
  const setIsUserWalletNotificationHistory = useStore((state) => state.setIsUserWalletNotificationHistory);
  const setIsDeleteUser = useStore((state) => state.setIsDeleteUser);
  const setIsUserSubscriptionHistory = useStore((state) => state.setIsUserSubscriptionHistory);
  const setIsAddUser = useStore((state) => state.setIsAddUser);
  const setIsUserSubscription = useStore((state) => state.setIsUserSubscription);
  const setIsAddSubcription = useStore((state) => state.setIsAddSubcription);
  const setIsAddVideo = useStore((state) => state.setIsAddVideo);
  const setIsEditVideo = useStore((state) => state.setIsEditVideo);
  const setIsDeleteVideo = useStore((state) => state.setIsDeleteVideo);
  const setIsEditSubcription = useStore((state) => state.setIsEditSubcription);
  const setIsDeleteSubcription = useStore((state) => state.setIsDeleteSubcription);
  const setIsEditUser = useStore((state) => state.setIsEditUser);
  const setAdminEmail = useStore((state) => state.setAdminEmail);
  const setAdminId = useStore((state) => state.setAdminId);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setisLoading] = useState(false);
  let navigate = useNavigate();

  const checkData = async () => {
    if (isLoggedIn) {
      navigate("/", { replace: true });
    }
  };

  const handleLogin = async () => {
    setisLoading(true);
    let data = {
      email: email,
      password: password,
    };
    const response = await adminLogin(data);

    if (response[0]) {
      login();
      setAdminEmail(email);
      setIsUser(response[1].isUser);
      setIsRedeem(response[1].isRedeem);
      setIsSubcription(response[1].isSubscription);
      setIsUserWallet(response[1].isUserWallet);
      setIsVideo(response[1].isVideo);
      setIsAccess(response[1].isAccess);
      setIsUserWalletHistory(response[1].isUserWalletHistory);
      setIsUserWalletNotificationHistory(response[1].isUserWalletNotificationHistory);
      setIsDeleteUser(response[1].isDeleteUser);
      setIsUserSubscriptionHistory(response[1].isUserSubscriptionHistory);
      setIsAddUser(response[1].isAddUser);
      setIsUserSubscription(response[1].isUserSubscription);
      setIsAddSubcription(response[1].isAddSubscription);
      setIsAddVideo(response[1].isAddVideo);
      setIsDeleteVideo(response[1].isDeleteVideo);
      setIsEditVideo(response[1].isEditVideo);
      setIsEditSubcription(response[1].isEditSubscription);
      setIsDeleteSubcription(response[1].isDeleteSubscription);
      setIsEditUser(response[1].isEditUser);
      setAdminId(response[1]._id);
      window.location.reload();

      setisLoading(false);
    } else {
      alert("wrong email or password");
    }
    setisLoading(false);
  };
  useEffect(() => {
    checkData();
  }, []);

  return (
    <div className="login-div">
      <div className="login-subdiv">
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <form>
            <h3>Sign In</h3>

            <br></br>
            <div className="form_div">
              <label>Email address</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <br></br>
            <div className="form_div">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <br></br>

            <button
              onClick={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
