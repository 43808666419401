import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Loading = () => {
  return (
    <center className="loading__container">
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    </center>
  );
};

export default Loading;