import { useState } from "react";
import useStore from "../utils/store";
import { adminVerifyPassword , adminUpdatePassword } from "../utils/api";

const ResetPassword = () => {
  const adminemail = useStore((state) => state.adminemail);

  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const verifyOldPassword = async () => {
    if (password.length < 1) {
      return;
    }
    setIsLoading(true);
    const data = {
      email: adminemail,
      password: password,
    };
    const result = await adminVerifyPassword(data);
    if (result[0]) {
      setIsVerified(true);
    } else {
      alert("Wrong Password");
    }
    setIsLoading(false);
  };

  const updateNewPassword = async() =>{
    if (newPassword.length < 1) {
        return;
      }
      setIsLoading(true);
      const data = {
        email: adminemail,
        password: newPassword,
      };
      const result = await adminUpdatePassword(data);
      if (result[0]) {
        setIsVerified(false);
        setPassword("");
        setNewPassword("");
        alert("Password has been updated")
      } else {
        alert("Wrong Password");
      }
      setIsLoading(false);
  }

  return (
    <div className="container noselect">
      <h3>Reset Password</h3>
      {isVerified ? (
        <div>
          <h4>Enter New Password</h4>
          <input
            type="text"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <button
            onClick={(e)=>{
                e.preventDefault();
                updateNewPassword()
            }}
          >Update Password</button>
        </div>
      ) : (
        <div >
          <h4>Enter Old Password</h4>
          <input
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <br />
          <button
            onClick={(e)=>{
                e.preventDefault();
                verifyOldPassword();
            }}
          >Verify Password</button>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
