import { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import useStore from "../utils/store";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import AddBoxIcon from "@mui/icons-material/AddBox";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import RedeemIcon from "@mui/icons-material/Redeem";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import SwitchVideoIcon from "@mui/icons-material/SwitchVideo";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,

  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Navbar({ open, setOpen }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const logout = useStore((state) => state.logout);
  const isUser = useStore((state) => state.isUser);
  const isUserWallet = useStore((state) => state.isUserWallet);
  const isUserWalletHistory = useStore((state) => state.isUserWalletHistory);
  const isRedeem = useStore((state) => state.isRedeem);
  const isSubscription = useStore((state) => state.isSubscription);
  const isVideo = useStore((state) => state.isVideo);
  const isAccess = useStore((state) => state.isAccess);
  const isUserWalletNotificationHistory = useStore(
    (state) => state.isUserWalletNotificationHistory
  );
  const isDeleteUser = useStore((state) => state.isDeleteUser);
  const isUserSubscriptionHistory = useStore(
    (state) => state.isUserSubscriptionHistory
  );
  const isAddUser = useStore((state) => state.isAddUser);
  const isUserSubscription = useStore((state) => state.isUserSubscription);
  const isAddSubscription = useStore((state) => state.isAddSubscription);
  const isAddVideo = useStore((state) => state.isAddVideo);
  const isEditVideo = useStore((state) => state.isEditVideo);
  const isDeleteVideo = useStore((state) => state.isDeleteVideo);
  const isEditSubscription = useStore((state) => state.isEditSubscription);
  const isDeleteSubscription = useStore((state) => state.isDeleteSubscription);
  const isEditUser = useStore((state) => state.isEditUser);

  const [name, setName] = useState(["Home"]);
  const [link, setLink] = useState(["/"]);
  const [icon, setIcon] = useState([<HomeIcon className="listItems" />]);

  const [isLoading, setIsLoading] = useState(true);

  const handleLogout = () => {
    logout();
    navigate("/login", { replace: true });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    setIsLoading(true);
    let newArr = ["Home"];
    let newIcons = [<HomeIcon className="listItems" />];
    let newLink = ["/"];
    if (isUser) {
      newIcons = [...newIcons, <PersonIcon className="listItems" />];
      newArr = [...newArr, "Users"];
      newLink = [...newLink, "/users"];
    }
    if (isEditUser) {
      newIcons = [...newIcons, <PersonIcon className="listItems" />];
      newArr = [...newArr, "Edit Users"];
      newLink = [...newLink, "/edit-user"];
    }

    if (isUserWalletHistory) {
      newIcons = [...newIcons, <PlayCircleIcon className="listItems" />];
      newArr = [...newArr, "User Wallet History"];
      newLink = [...newLink, "/users-wallet-history"];
    }

    if (isUserWallet) {
      newIcons = [...newIcons, <PlayCircleIcon className="listItems" />];
      newArr = [...newArr, "User Wallet"];
      newLink = [...newLink, "/users-wallet"];
    }

    if (isUserWalletNotificationHistory) {
      newIcons = [...newIcons, <ForwardToInboxIcon className="listItems" />];
      newArr = [...newArr, "Notification History"];
      newLink = [...newLink, "/users-notification"];
    }

    if (isDeleteUser) {
      newIcons = [...newIcons, <PersonRemoveIcon className="listItems" />];
      newArr = [...newArr, "Delete User"];
      newLink = [...newLink, "/delete-user"];
    }

    if (isUserSubscriptionHistory) {
      newIcons = [...newIcons, <HistoryToggleOffIcon className="listItems" />];
      newArr = [...newArr, "Subscription History"];
      newLink = [...newLink, "/subscription-history"];
    }
    if (isAddUser) {
      newIcons = [...newIcons, <PersonAddIcon className="listItems" />];
      newArr = [...newArr, "Add User"];
      newLink = [...newLink, "/add-user"];
    }
    if (isUserSubscription) {
      newIcons = [...newIcons, <CardMembershipIcon className="listItems" />];
      newArr = [...newArr, "User Subscription"];
      newLink = [...newLink, "/user-subscription"];
    }

    if (isRedeem) {
      newIcons = [...newIcons, <RedeemIcon className="listItems" />];
      newArr = [...newArr, "Redeem Request"];
      newLink = [...newLink, "/redeem-request"];
    }

    if (isSubscription) {
      newIcons = [
        ...newIcons,
        <SubscriptionsIcon className="listItems" />,
      ];
      newArr = [...newArr, "Subscriptions"];
      newLink = [...newLink, "/subscriptions"];
    }

    if(isAddSubscription){
      newIcons = [
        ...newIcons,
        <AddBoxIcon className="listItems" />,
      ];
      newArr = [...newArr,  "Add Subscription"];
      newLink = [...newLink, "/add-subscription"];
    }

    if(isEditSubscription){
      newIcons = [
        ...newIcons,
        <AddBoxIcon className="listItems" />,
      ];
      newArr = [...newArr,  "Edit Subscription"];
      newLink = [...newLink, "/edit-subscription"];
    }

    if(isDeleteSubscription){
      newIcons = [
        ...newIcons,
        <AddBoxIcon className="listItems" />,
      ];
      newArr = [...newArr,  "Delete Subscription"];
      newLink = [...newLink, "/delete-subscription"];
    }

    if (isVideo) {
      newIcons = [
        ...newIcons,
        <SwitchVideoIcon className="listItems" />,
      ];
      newArr = [...newArr, "Videos"];
      newLink = [...newLink, "/videos"];
    }

    if (isAddVideo) {
      newIcons = [
        ...newIcons,
        <OndemandVideoIcon className="listItems" />,
      ];
      newArr = [...newArr,  "Add Video"];
      newLink = [...newLink,  "/add-video"];
    }

    if (isEditVideo) {
      newIcons = [
        ...newIcons,
        <OndemandVideoIcon className="listItems" />,
      ];
      newArr = [...newArr,  "Edit Video"];
      newLink = [...newLink,  "/edit-videos"];
    }
    if (isDeleteVideo) {
      newIcons = [
        ...newIcons,
        <OndemandVideoIcon className="listItems" />,
      ];
      newArr = [...newArr,  "Delete Video"];
      newLink = [...newLink,  "/delete-videos"];
    }

    if (isAccess) {
      newIcons = [
        ...newIcons,
        <SupervisorAccountIcon className="listItems" />,
        <AdminPanelSettingsIcon className="listItems" />,
      ];
      newArr = [...newArr, "Admins", "Add Admin"];
      newLink = [...newLink, "/admins", "/add-admin"];
    }

    setName([...newArr]);
    setIcon([...newIcons]);
    setLink([...newLink]);
    setIsLoading(false);
  };

  useEffect(() => {
    handleAdd();
  }, [isLoggedIn, isUser, isAccess, isSubscription, isVideo, isRedeem]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Swiss Kraft
          </Typography>
          <div className="navbar_maxcontainer">
          {
            isLoggedIn ?
            <button
            className="medium-button"
            style={{ marginLeft: "auto", backgroundColor: "red" }}
            onClick={(e) => {
              e.preventDefault();
               navigate("/resetpassword", { replace: true });
            }}
          >
            {<div>Reset Password</div>}
          </button> :  <></>}
          <button
            className="medium-button"
            style={{ marginLeft: "auto", backgroundColor: "red" }}
            onClick={(e) => {
              e.preventDefault();
              isLoggedIn
                ? handleLogout()
                : navigate("/login", { replace: true });
            }}
          >
            {isLoggedIn ? <div>Logout</div> : <div>Login</div>}
          </button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {!isLoading && (
          <>
            <List>
              {name.map((text, index) => (
                <ListItem
                  key={text}
                  disablePadding
                  sx={{ display: "block" }}
                  className="listItems"
                  onClick={() => {
                    navigate(link[index]);
                    handleDrawerClose();
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {icon[index]}
                    </ListItemIcon>
                    <ListItemText
                      primary={text}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                  <Divider />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <div className="spacer" />
      </Box>
    </Box>
  );
}
