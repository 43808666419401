import { useEffect, useState } from "react";
import Loading from "../components/Loading";
import {

  getAllSubscriptions,
  removeVideoFromSubscription,
} from "../utils/api";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Collapsible from "react-collapsible";
import SubscriptionEditContainer from "../components/SubscriptionEditContainer";
import useStore from "../utils/store";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const EditSubscription = () => {
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);
    const [openSubscription, setOpenSubscription] = useState(false);
    const [subscription, setSubscription] = useState();
    const isLoggedIn = useStore((state) => state.isLoggedIn);
  
    const getsubscriptions = async () => {
      setIsLoading(true);
      const response = await getAllSubscriptions();
      if (response[0]) {
        setSubscriptions(response[1]);
      }
      setIsLoading(false);
    };
  
    const handleCloseSubscription = () => {
      setOpenSubscription(false);
    };
  
    const removeVideo = async (vId, sId) => {
      setIsLoading(true);
      let data = {
        videoId: vId,
        id: sId,
      };
      await removeVideoFromSubscription(data);
      await getsubscriptions();
    };
  
  
    const checkData = async () => {
      if (!isLoggedIn) {
        navigate("/login" , {replace :true} );
      }  else {
        getsubscriptions();
      }
    };
  
  
    useEffect(() => {
      checkData();
    }, []);
  
    if (isLoading) return <Loading />;
  
    return (
      <div style={{ marginLeft: "20px" }}>
        <SubscriptionEditContainer
          open={openSubscription}
          handleClose={handleCloseSubscription}
          subscription={subscription}
          reload={getsubscriptions}
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Amount</StyledTableCell>
                <StyledTableCell align="center">Period</StyledTableCell>
                <StyledTableCell align="center">Edit</StyledTableCell>
                <StyledTableCell align="center">Number of videos</StyledTableCell>
                <StyledTableCell align="center">Videos</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.map((subscription, index) => (
                <StyledTableRow key={subscription._id}>
                  <StyledTableCell align="center">
                    {subscription.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    ₹{subscription.amount}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {subscription.period} days
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <button
                      className="medium-button"
                      onClick={(e) => {
                        e.preventDefault();
                        setSubscription(subscription);
                        setOpenSubscription(true);
                      }}
                    >
                      Edit Subscription
                    </button>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {subscription.numberOfVideos}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Collapsible
                      trigger="Click Here To View"
                      className="collapse noselect"
                    >
                      {subscription.videos.map((video, i) => (
                        <div className="noselect row" key={i}>
                          <div>
                            <div>
                              {i + 1}. Url: <a href={video.url}> {video.url}</a>
                            </div>
                            <div>Amount: ₹{video.amount}</div>
                            <div>Ads Count: {video.ads.length}</div>
                            <div>Views: {video.views}</div>
                          </div>
                          <button
                            className="medium-button"
                            onClick={(e) => {
                              e.preventDefault();
                              removeVideo(video._id, subscription._id);
                            }}
                          >
                            Remove Video
                          </button>
                        </div>
                      ))}
                    </Collapsible>
                  </StyledTableCell>
                  
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
}

export default EditSubscription