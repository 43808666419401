import { useEffect, useState } from "react";
import Loading from "../components/Loading";
import {
  getAllSubscriptions,
} from "../utils/api";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Collapsible from "react-collapsible";
import useStore from "../utils/store";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Subscriptions = () => {
  let navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const isLoggedIn = useStore((state) => state.isLoggedIn);

  const getsubscriptions = async () => {
    setIsLoading(true);
    const response = await getAllSubscriptions();
    if (response[0]) {
      setSubscriptions(response[1]);
    }
    setIsLoading(false);
  };



  const checkData = async () => {
    if (!isLoggedIn) {
      navigate("/login" , {replace :true} );
    }  else {
      getsubscriptions();
    }
  };


  useEffect(() => {
    checkData();
  }, []);

  if (isLoading) return <Loading />;

  return (
    <div style={{ marginLeft: "20px" }}>
     
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Description</StyledTableCell>
              <StyledTableCell align="center">Amount</StyledTableCell>
              <StyledTableCell align="center">Period</StyledTableCell>
              <StyledTableCell align="center">Number of videos</StyledTableCell>
              <StyledTableCell align="center">Videos</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptions.map((subscription, index) => (
              <StyledTableRow key={subscription._id}>
                <StyledTableCell align="center">
                  {subscription.name}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {subscription.description}
                </StyledTableCell>
                <StyledTableCell align="center">
                  ₹{subscription.amount}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {subscription.period} days
                </StyledTableCell>
               
                <StyledTableCell align="center">
                  {subscription.numberOfVideos}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Collapsible
                    trigger="Click Here To View"
                    className="collapse noselect"
                  >
                    {subscription.videos.map((video, i) => (
                      <div className="noselect row" key={i}>
                        <div>
                          <div>
                            {i + 1}. Url: <a href={video.url}> {video.url}</a>
                          </div>
                          <div>Amount: ₹{video.amount}</div>
                          <div>Ads Count: {video.ads.length}</div>
                          <div>Views: {video.views}</div>                       
                        </div>
                        
                      </div>
                    ))}
                  </Collapsible>
                </StyledTableCell>
               
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Subscriptions;
