import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import { getNotifications } from "../utils/api";

const NotificationContainer = ({ open, handleClose, userId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);

  const getNotificationDetails = async () => {
    if (userId) {
      setIsLoading(true);
      const response = await getNotifications(userId);
      if (response[0]) {
        setNotifications(response[1]);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNotificationDetails();
  }, [userId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="noselect history__container"
      >
        {isLoading ? (
          <div className="dialog__container">
            <center>
              <CircularProgress />
            </center>
          </div>
        ) : (
          <div>
            {notifications.map((notification, index) => (
              <div key={index} className="column">
                <div className="notification_container">
                  <div className="historylist__container_index" style={{ marginRight: "1em"}}>
                    {index + 1}.
                  </div>
                  <img src={notification.image} alt="" height={80} width={80}  style={{ marginRight: "3em"}}/>
                  <b>{notification.title}</b>
                </div>
                <br />
                <div>{notification.description}</div>
              </div>
            ))}
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default NotificationContainer;
