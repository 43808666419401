import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    primary:{
        main:"#5E35B1"
    },
    secondary: {
      main: "#EDE7F6",
    },
    dark:{
        main: "#5E35B1"
    },
    background: {
        default: "#E3F2FD"
    },
  },
});

export default theme;