import { useState , useEffect } from "react";
import Loading from "../components/Loading";
import { addNewUser } from "../utils/api";
import { useNavigate } from "react-router-dom";
import useStore from "../utils/store";

const AddUser = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const isLoggedIn = useStore((state) => state.isLoggedIn);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [upi, setUpi] = useState("");
  const [dob, setDob] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pancard, setPancard] = useState(null);

  const onImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setPancard(file);
    } else {
      setPancard(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("dob", dob);
    formData.append("ifsc", ifsc);
    formData.append("bankName", bankName);
    formData.append("accountNumber", accountNumber);
    formData.append("aadharNumber", aadharNumber);
    formData.append("phoneNumber", phoneNumber);
    formData.append("pancard", pancard);

    const response = await addNewUser(formData);

    if (response[0]) {
        setName("");
        setEmail("");
        setPassword("");
        setAadharNumber("");
        setIfsc("");
        setAccountNumber("");
        setBankName("");
        setUpi("");
        setDob("");
        setPhoneNumber("");
        setPancard(null);
      alert("new user added");
    } else {
      alert(`${response[1]}`);
    }
    setIsLoading(false);
  };


  
  const checkData = async () => {
   
    if (!isLoggedIn) {
      navigate("/login" , {replace :true} );
    }
  };


  useEffect(() => {
    checkData();
  }, []);

  if (isLoading) return <Loading />;


  return (
    <div className="container noselect">
      <h2>Add New User</h2>
      <form onSubmit={handleSubmit} className="form">
        <div className="form_div">
          <label>User Name:</label> &nbsp;
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form_div">
          <label>Email:</label> &nbsp;
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form_div">
          <label>Password:</label> &nbsp;
          <input
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form_div">
          <label>Phone Number:</label> &nbsp;
          <input
            type="number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <div className="form_div">
          <label>Aadhar Number:</label> &nbsp;
          <input
            type="text"
            value={aadharNumber}
            onChange={(e) => setAadharNumber(e.target.value)}
            required
          />
        </div>
        <div className="form_div">
          <label>Ifsc: &nbsp;</label>
          <input
            type="text"
            value={ifsc}
            onChange={(e) => setIfsc(e.target.value)}
            required
          />
        </div>
        <div className="form_div">
          <label>Account Number: &nbsp;</label>
          <input
            type="text"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            required
          />
        </div>
        <div className="form_div">
          <label>Bank Name: &nbsp;</label>
          <input
            type="text"
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
            required
          />
        </div>
        <div className="form_div">
          <label>Upi Id: &nbsp;</label>
          <input
            type="text"
            value={upi}
            onChange={(e) => setUpi(e.target.value)}
            required
          />
        </div>
        <div className="form_div">
          <label>Dob: &nbsp;</label>
          <input
            type="text"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            required
          />
        </div>
        <div className="form_div">
          <label>Pancard Image: &nbsp;</label>
          <input type="file" onChange={onImageChange} required />
        </div>
        <br />
        <button type="Submit"> Add User </button>
      </form>
    </div>
  );
};

export default AddUser;
