import create from "zustand";
import { persist, devtools } from "zustand/middleware";

let store = (set) => ({
  isLoggedIn: false,
  login: () => set((state) => ({ isLoggedIn: true })),
  logout: () =>
    set((state) => ({
      isLoggedIn: false,
      isUser: false,
      isRedeem: false,
      isSubscription: false,
      isVideo: false,
      isAccess: false,
      isUserWallet: false,
      isUserWalletHistory: false,
      isUserWalletNotificationHistory: false,
      isDeleteUser: false,
      isUserSubscriptionHistory: false,
      isAddUser: false,
      isUserSubscription: false,
      isAddSubscription: false,
      isAddVideo: false,
      isDeleteVideo: false,
      isEditVideo: false,
      isEditSubscription: false,
      isDeleteSubscription: false,
      isEditUser: false,
      adminemail: null,
      adminId: null
    })),
  adminemail: null,
  setAdminEmail: (newVal) => set((state) => ({ adminemail: newVal })),
  adminId : null,
  setAdminId: (newVal) => set((state) => ({ adminId: newVal })),
  isUser: false,
  setIsUser: (newVal) => set((state) => ({ isUser: newVal })),
  isEditUser: false,
  setIsEditUser: (newVal) => set((state) => ({ isEditUser: newVal })),
  isUserWallet: false,
  setIsUserWallet: (newVal) => set((state) => ({ isUserWallet: newVal })),
  isUserWalletHistory: false,
  setIsUserWalletHistory: (newVal) => set((state) => ({ isUserWalletHistory: newVal })),
  isUserWalletNotificationHistory: false,
  setIsUserWalletNotificationHistory: (newVal) => set((state) => ({ isUserWalletNotificationHistory: newVal })),
  isDeleteUser: false,
  setIsDeleteUser: (newVal) => set((state) => ({ isDeleteUser: newVal })),
  isUserSubscriptionHistory: false,
  setIsUserSubscriptionHistory: (newVal) => set((state) => ({ isUserSubscriptionHistory: newVal })),
  isAddUser: false,
  setIsAddUser: (newVal) => set((state) => ({ isAddUser: newVal })),
  isUserSubscription: false,
  setIsUserSubscription: (newVal) => set((state) => ({ isUserSubscription: newVal })),
  isRedeem: false,
  setIsRedeem: (newVal) => set((state) => ({ isRedeem: newVal })),
  isSubscription: false,
  setIsSubcription: (newVal) => set((state) => ({ isSubscription: newVal })),
  isAddSubscription: false,
  setIsAddSubcription: (newVal) => set((state) => ({ isAddSubscription: newVal })),
  isEditSubscription: false,
  setIsEditSubcription: (newVal) => set((state) => ({ isEditSubscription: newVal })),
  isDeleteSubscription: false,
  setIsDeleteSubcription: (newVal) => set((state) => ({ isDeleteSubscription: newVal })),
  isVideo: false,
  setIsVideo: (newVal) => set((state) => ({ isVideo: newVal })),
  isAddVideo: false,
  setIsAddVideo: (newVal) => set((state) => ({ isAddVideo: newVal })),
  isEditVideo: false,
  setIsEditVideo: (newVal) => set((state) => ({ isEditVideo: newVal })),
  isDeleteVideo: false,
  setIsDeleteVideo: (newVal) => set((state) => ({ isDeleteVideo: newVal })),
  isAccess: false,
  setIsAccess: (newVal) => set((state) => ({ isAccess: newVal })),
});

store = persist(store, { name: "tangent_yt_user_settings" });
store = devtools(store);

const useStore = create(store);

export default useStore;
