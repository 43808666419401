import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import { adminEditSubscription } from "../utils/api";

const SubscriptionEditContainer = ({
  open,
  handleClose,
  subscription,
  reload,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [period, setPeriod] = useState("");
  const [description, setDescription] = useState("");

  const updateData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let data = {
      name: name,
      period: period,
      amount: amount,
      id: subscription._id,
      description: description
    };
    const response = await adminEditSubscription(data);
    if (response[1]) {
      await reload();
      handleClose();
      setIsLoading(false);
    }
  };

  const loadData = () => {
    if (subscription) {
      setIsLoading(true);
      setName(subscription.name);
      setAmount(subscription.amount);
      setPeriod(subscription.period);
      setDescription(subscription.description);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [subscription]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="noselect history__container"
      >
        {isLoading ? (
          <div className="dialog__container">
            <center>
              <CircularProgress />
            </center>
          </div>
        ) : (
          <div className="dialog__container">
            <form onSubmit={updateData} className="form">
              <div className="form_div">
                <label>Name: &nbsp;</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form_div">
                <label>Description: &nbsp;</label>
                <input
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </div>
              <div className="form_div">
                <label>Amount: &nbsp;</label>
                <input
                  type="text"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                />
              </div>

              <div className="form_div">
                <label>Period: &nbsp;</label>
                <input
                  type="text"
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                  required
                />
              </div>
              <br />
              <button type="submit" className="medium__button">
                Update
              </button>
            </form>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default SubscriptionEditContainer;
