import { useState , useEffect } from "react";
import AlertDialog from "../components/AlertDialog.jsx";
import Loading from "../components/Loading.jsx";

import { addSubscription } from "../utils/api.js";
import { useNavigate } from "react-router-dom";
import useStore from "../utils/store";

const AddSubscription = () => {
  let navigate = useNavigate();
  const [name, setName] = useState("");
  const [description , setDescription] = useState("");
  const [amount, setAmount] = useState(0);
  const [period, setPeriod] = useState(0);
  const isLoggedIn = useStore((state) => state.isLoggedIn);

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Error");
  const handleClose = () => {
    setOpen(false);
    setAlertTitle("Error");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    setIsLoading(true);
    const data = {
      name: name,
      description: description,
      amount: amount,
      period: period,
    };
    const response = await addSubscription(data);
    if (response[0]) {
      setAmount(0);
      setName("");
      setDescription("");
      setPeriod(0);
      setAlertTitle("Subscription added!");
      setOpen(true);
    } else {
      setAlertTitle(response[1].message);
      setOpen(true);
    }
    setIsLoading(false);
  };

  const checkData = async () => {
    if (!isLoggedIn) {
      navigate("/login" , {replace :true} );
    }
  };


  useEffect(() => {
    checkData();
  }, []);

  if (isLoading) return <Loading />;

  return (
    <div className="container noselect">
      <h2>Add Subscription</h2>
      <AlertDialog
        open={open}
        handleClose={handleClose}
        selected={handleClose}
        title={alertTitle}
      />
      <form onSubmit={handleSubmit} className="form">
        <div className="form_div">
          <label >Subscription Name:</label> &nbsp;
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form_div">
          <label >Subscription Description:</label> &nbsp;
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div className="form_div">
          <label >Subscription Amount: {" (₹) "} </label> &nbsp;
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <div className="form_div">
          <label >Subscription period (in days):</label> &nbsp;
          <input
            type="number"
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
            required
          />
        </div>
        <hr className="dotted" />
        <br />
        <br />
        <button type="Submit"> Save Subscription </button>
      </form>
    </div>
  );
};

export default AddSubscription;
