import "../css/user.css";
import { useEffect, useState } from "react";
import Loading from "../components/Loading";
import {  getAllUsers } from "../utils/api";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import UserContainer from "../components/UserContainer";
import { useNavigate } from "react-router-dom";
import useStore from "../utils/store";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const EditUser = () => {
    let navigate = useNavigate();
    const isLoggedIn = useStore((state) => state.isLoggedIn);
  
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);
  
    const [user, setUser] = useState();
    const [userContainerOpen, setUserContainerOpen] = useState(false);
  
    const handleCloseUserContainer = () => {
      setUserContainerOpen(false);
    };
  
    const getUsers = async () => {
      const response = await getAllUsers();
      if (response[0]) {
        setUsers(response[1]);
      }
      setIsLoading(false);
    };
  
   
  
    const checkData = async () => {
      if (!isLoggedIn) {
        navigate("/login", { replace: true });
      } else {
        getUsers();
      }
    };
  
    useEffect(() => {
      checkData();
    }, []);
  
    if (isLoading) return <Loading />;
  
    return (
      <div style={{ marginLeft: "20px" }}>
        <UserContainer
          open={userContainerOpen}
          handleClose={handleCloseUserContainer}
          user={user}
          reload={getUsers}
        />
        <TableContainer component={Paper} sx={{ marginLeft: "10px" }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Aadhar Number</StyledTableCell>
                <StyledTableCell align="center">Phone Number</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Bank Details</StyledTableCell>
                <StyledTableCell align="center">Wallet</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <StyledTableRow key={user._id}>
                  <StyledTableCell align="center">{user.name}</StyledTableCell>
                  <StyledTableCell align="center">
                    {user.aadharNumber}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {user.phoneNumber}
                  </StyledTableCell>
                  <StyledTableCell align="center">{user.email}</StyledTableCell>
                  <StyledTableCell align="center">
                    <div>
                      Account Number: {user.accountNumber}
                      <br />
                      Bank Name: {user.bankName}
                      <br />
                      IFSC: {user.ifsc}
                      <br />
                      UPI: {user.upi}
                      <br />
                      Pan Card:{" "}
                      <a href={user.pancard} download target="_blank">
                        Click to download
                      </a>
                    </div>{" "}
                  </StyledTableCell>
                  <StyledTableCell align="center">₹{user.wallet}</StyledTableCell>
                  <StyledTableCell align="center">
                    <button
                      className="small-button"
                      onClick={(e) => {
                        e.preventDefault();
                        setUser(user);
                        setUserContainerOpen(true);
                      }}
                    >
                      Update User
                    </button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
}

export default EditUser