import {useState , useEffect} from 'react'
import Loading from '../components/Loading';
import { unsubscribedUser , getAllSubscriptions } from '../utils/api';

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SubscriptionContainer from '../components/SubscriptionContainer';
import { useNavigate } from "react-router-dom";
import useStore from "../utils/store";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


const UserSubscription = () => {
  let navigate = useNavigate();
  const isLoggedIn = useStore((state) => state.isLoggedIn);

  const [isLoading , setIsLoading] = useState(true);
  const [users , setUsers] = useState([]);
  const [subscriptions , setSubscriptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [userId , setUserId] = useState();

  const getUsers = async () => {
    const response = await unsubscribedUser();
    if (response[0]) {
      setUsers(response[1]);
      const subscriptionResponse = await getAllSubscriptions();
      if (subscriptionResponse[0]) {
        setSubscriptions(subscriptionResponse[1]);
      }else{
        alert("Error")
      }
    }else{
      alert("Error")
    }
    setIsLoading(false);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const checkData = async () => {
    if (!isLoggedIn) {
      navigate("/login" , {replace :true} );
    } else {
      getUsers();
    }
  };


  useEffect(() => {
    checkData();
  }, []);


  if (isLoading) return <Loading />;

  return (
 <div>
  <h2 style={{textAlign: "center"}}>Expired Subscription Users</h2>
  <SubscriptionContainer open={open} handleClose={handleClose} userId={userId} subscriptions={subscriptions} reload={getUsers} />
     <TableContainer component={Paper}>
    <Table sx={{ minWidth: 700 }} aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell align="center">Name</StyledTableCell>
          <StyledTableCell align="center">Phone Number</StyledTableCell>
          <StyledTableCell align="center">Email</StyledTableCell>
          <StyledTableCell align="center">
            Add Subscription
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user, index) => (
          <StyledTableRow key={user._id}>
            <StyledTableCell align="center">{user.name}</StyledTableCell>
            <StyledTableCell align="center">
              {user.phoneNumber}
            </StyledTableCell>
            <StyledTableCell align="center">{user.email}</StyledTableCell>
            <StyledTableCell align="center">
              <button className="small-button"
               onClick={(e) => {
                e.preventDefault();
                setUserId(user._id);
                setOpen(true);
              }}
              >Add Subscription</button>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
 </div>
  )
}

export default UserSubscription