import "./App.css";

import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Users from "./pages/Users";
import Subscriptions from "./pages/Subscriptions";
import RedeemRequest from "./pages/RedeemRequest";
import Navbar from "./components/Navbar";
import AddSubscription from "./pages/AddSubscription";
import SubscriptionHistory from "./pages/SubscriptionHistory";
import AddUser from "./pages/AddUser";
import UserSubscription from "./pages/UserSubscription";
import AddVideo from "./pages/AddVideo";
import Videos from "./pages/Videos";
import Login from "./pages/Login";
import AddAdmin from "./pages/AddAdmin";
import Admins from "./pages/Admins";
import UserWalletHistory from "./pages/UserWalletHistory";
import UserNotification from "./pages/UserNotification";
import DeleteUser from "./pages/DeleteUser";
import { DeleteVideo } from "./pages/DeleteVideo";
import EditVideo from "./pages/EditVideo";
import EditSubscription from "./pages/EditSubscription";
import DeleteSubscription from "./pages/DeleteSubscription";
import EditUser from "./pages/EditUser";
import  UserAddWallet  from "./pages/UserAddWallet";
import ResetPassword from "./pages/ResetPassword";

const App = () => {
  const [open , setOpen] = useState(false)
  return (
    <div>
      <Navbar open={open} setOpen={setOpen}/>
      <div className="app__container">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users-wallet-history" element={<UserWalletHistory />} />
        <Route path="/users-wallet" element={<UserAddWallet />} />
        <Route path="/users-notification" element={<UserNotification />} />
        <Route path="/delete-user" element={<DeleteUser />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/redeem-request" element={<RedeemRequest />} />        
        <Route path="/add-subscription" element={<AddSubscription />} />        
        <Route path="/subscription-history" element={<SubscriptionHistory />} />        
        <Route path="/redeem-request" element={<RedeemRequest />} />        
        <Route path="/add-user" element={<AddUser />} />        
        <Route path="/user-subscription" element={<UserSubscription />} />        
        <Route path="/add-video" element={<AddVideo />} />          
        <Route path="/videos" element={<Videos />} />          
        <Route path="/delete-videos" element={<DeleteVideo />} />          
        <Route path="/edit-videos" element={<EditVideo />} />          
        <Route path="/login" element={<Login />} />          
        <Route path="/add-admin" element={<AddAdmin />} />          
        <Route path="/admins" element={<Admins />} />          
        <Route path="/edit-subscription" element={<EditSubscription />} />          
        <Route path="/delete-subscription" element={<DeleteSubscription />} />          
        <Route path="/edit-user" element={<EditUser />} />          
        <Route path="/resetpassword" element={<ResetPassword />} />          
      </Routes>
      </div>
    </div>
  );
};

export default App;
