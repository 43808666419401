import { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { getAllRedeemRequest, updateRedeemRequest , getAllSystemValues} from "../utils/api";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import useStore from "../utils/store";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const RedeemRequest = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [created, setCreated] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [cancelled, setCancelled] = useState([]);
  const [adminCharges, setAdminCharges] = useState(0);
  const isLoggedIn = useStore((state) => state.isLoggedIn);

  const [value, setValue] = useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getData = async () => {
    const system = await getAllSystemValues();
    setAdminCharges(system[1].adminCharges);
    const response = await getAllRedeemRequest();
    if (response[0]) {
      setCreated(response[1].created);
      setCompleted(response[1].completed);
      setCancelled(response[1].decline);
    } else {
      alert(`${response[1]}`);
    }
    setIsLoading(false);
  };

  const updateRequest = async (id, status, amount, userId) => {
    setIsLoading(true);
    const data = {
      status: status,
      amount: amount,
      userId: userId,
    };
    const response = await updateRedeemRequest(id, data);
    if (response[0]) {
      setCreated(response[1].created);
      setCompleted(response[1].completed);
      setCancelled(response[1].decline);
    } else {
      alert(`${response[1]}`);
    }

    setIsLoading(false);
  };

  const checkData = async () => {
    
    if (!isLoggedIn) {
      navigate("/login" , {replace :true} );
    }else {
      getData();
    }
  };


  useEffect(() => {
    checkData();
  }, []);

  function getTable(requests, isAction) {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Name</StyledTableCell>
              {isAction ? (
                <StyledTableCell align="center">Amount</StyledTableCell>
              ) : (
                <></>
              )}
              <StyledTableCell align="center">Date</StyledTableCell>
              <StyledTableCell align="center">Bank Details</StyledTableCell>
              {isAction ? (
                <StyledTableCell align="center">Action</StyledTableCell>
              ) : (
                <></>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((request, index) => (
              <StyledTableRow key={request._id}>
                <StyledTableCell align="center">
                  {request.user.name}
                </StyledTableCell>
                {isAction ? (
                  <StyledTableCell align="center">
                    {request.user.wallet - adminCharges}
                  </StyledTableCell>
                ) : (
                  <></>
                )}
                <StyledTableCell align="center">
                  {request.createdAt}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div>
                    Account Number: {request.user.accountNumber}
                    <br />
                    Bank Name: {request.user.bankName}
                    <br />
                    IFSC: {request.user.ifsc}
                    <br />
                    UPI: {request.user.upi}
                    <br />
                    Pan Card:{" "}
                    <a href={request.user.pancard} download target="_blank">
                      Click to download
                    </a>
                  </div>
                </StyledTableCell>

                {isAction ? (
                  <StyledTableCell align="center">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        updateRequest(
                          request._id,
                          "Completed",
                          request.user.wallet,
                          request.user._id
                        );
                      }}
                    >
                      Done
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        updateRequest(
                          request._id,
                          "Decline",
                          request.user.wallet,
                          request.user._id
                        );
                      }}
                    >
                      Cancel
                    </button>
                  </StyledTableCell>
                ) : (
                  <></>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  if (isLoading) return <Loading />;

  return (
    <div className="conatiner ">
      <Box sx={{ width: "100%", marginLeft: "2rem" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="secondary tabs example"
          variant="fullWidth"
        >
          <Tab value="one" label="New" />
          <Tab value="two" label="Completed" />
          <Tab value="three" label="Decline" />
        </Tabs>
        <br />
        {value === "one" ? (
          <div>{getTable(created, true)}</div>
        ) : value === "two" ? (
          <div>{getTable(completed)}</div>
        ) : (
          <div>{getTable(cancelled)}</div>
        )}
      </Box>
    </div>
  );
};

export default RedeemRequest;
