import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { adminUpdatePassword } from "../utils/api";
import CircularProgress from "@mui/material/CircularProgress";

const PasswordContainer = ({ open, handleClose, email , reload }) => {
  const [isLoading, setIsLoading] = useState(true);

    const [password , setPassword] = useState("");

  

  const updateData = async (e) => {
    if(password.length < 1){
        return;
    }
    e.preventDefault();
    setIsLoading(true);
    const data = {
     email: email , 
     password : password
    };
    const response = await adminUpdatePassword(data);
    setIsLoading(false)
    if(response[0]){
      await reload();
        alert("Data updated")
    }else{
        alert("error")
    }
    handleClose();
  };

  useEffect(() => {
    setPassword("");
    setIsLoading(false);
  }, [email]);

  return (
    <div >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="noselect history__container"
      >
        {isLoading ? (
          <div className="dialog__container">
            <center>
              <CircularProgress />
            </center>
          </div>
        ) : (
          <div className="dialog__container">
            <form onSubmit={updateData} className="form">
              <div className="form_div">
                <label>New Password: &nbsp;</label>
                <input
                  type="text"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              
              
              <br />
              <button className="medium__button">Update</button>
            </form>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default PasswordContainer;
