import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import { adminEditVideo } from "../utils/api";

const VideoEditContainer = ({ open, handleClose, video, reload }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [url, setUrl] = useState("");
  const [amount, setAmount] = useState("");
  const [views, setViews] = useState("");
  const [isYoutube, setIsYoutube] = useState(false);
  const [title, setTitle] = useState("");
  const [banner, setBanner] = useState("");
  const [authorName, setAuthorName] = useState("");

  const updateData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let data = {
      id: video._id,
      url: url,
      views: views,
      amount: amount,
      isYoutube: isYoutube,
      title: title,
      banner: banner,
      authorName: authorName,
    };
    const response = await adminEditVideo(data);
    if (response[1]) {
      await reload();
      handleClose();
      setIsLoading(false);
    }
  };

  const loadData = () => {
    if (video) {
      setIsLoading(true);
      setUrl(video.url);
      setAmount(video.amount);
      setViews(video.views);
      setIsYoutube(video.isYoutube);
      setTitle(video.title);
      setBanner(video.banner);
      setAuthorName(video.authorName);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [video]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="noselect history__container"
      >
        {isLoading ? (
          <div className="dialog__container">
            <center>
              <CircularProgress />
            </center>
          </div>
        ) : (
          <div className="dialog__container">
            <form onSubmit={updateData} className="form">
              <div className="form_div">
                <label>URL: &nbsp;</label>
                <input
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  required
                />
              </div>
              <div className="form_div">
                <label>Amount: &nbsp;</label>
                <input
                  type="text"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                />
              </div>
              <div className="form_div">
                <label>Views: &nbsp;</label>
                <input
                  type="text"
                  value={views}
                  onChange={(e) => setViews(e.target.value)}
                  required
                />
              </div>
              <div className="form_div">
                <label>Is Youtube Video: &nbsp;</label>
                <input
                  type="checkbox"
                  checked={isYoutube ? "checked" : ""}
                  onChange={(e) => setIsYoutube(e.target.checked)}
                />
              </div>
              <div className="form_div">
                <label>Title: &nbsp;</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>
              <div className="form_div">
                <label>Banner: &nbsp;</label>
                <input
                  type="text"
                  value={banner}
                  onChange={(e) => setBanner(e.target.value)}
                  required
                />
              </div>
              <div className="form_div">
                <label>Author Name: &nbsp;</label>
                <input
                  type="text"
                  value={authorName}
                  onChange={(e) => setAuthorName(e.target.value)}
                  required
                />
              </div>
              <br />
              <button className="medium__button" type="submit">
                Update
              </button>
            </form>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default VideoEditContainer;
